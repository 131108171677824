<template>
  <div class="tablebody">
    <b-container fluid>
      <b-row class="justify-content-md-center">
        <b-col md="3">
          <b-input-group class="my-1">
            <b-form-input autofocus required v-model="requisite" placeholder="Реквизит" size="sm" style="background-color: #c6ced4" />

          </b-input-group>
          <b-input-group class="my-1">
            <b-form-input v-model="amount" placeholder="Сумма" size="sm" style="background-color: #c6ced4" />

          </b-input-group>
          <b-input-group class="my-1">
            <b-form-select v-model="select1" :options="selectList1" size="sm" style="background-color: #c6ced4"  >
            </b-form-select>
          </b-input-group>
        </b-col>
        <b-col md="1">
          <b-row align-h="center"  class="my-1">
            <b-btn class='button' size="sm" :disabled="!select1||!amount||!requisite" v-on:click="action(['validate'])"  v-if="isOwner||isBank" ><b-icon-arrow-clockwise> </b-icon-arrow-clockwise></b-btn>
          </b-row>
          <b-row align-h="center"  class="my-1">
            <b-btn class='button' size="sm" :disabled="!select1||!amount||!requisite" v-on:click="action(['validate','makePayment'])" v-if="isTerminal" ><b-icon-box-arrow-in-right> </b-icon-box-arrow-in-right></b-btn>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

</div>
</template>

<script>
import axios from 'axios';
import config from '../_services/config.json';

export default {
  name: 'Payment',
  data: () =>  ({
    selectList:[],
    selectList1: [],
    select1:null,
    filter: null,
    amount: '',
    requisite: '',
    billnum: '',
    service: null,
    type: [],
    firstList: [],
    role:'',
    statusCode: 1
  }),
  template: '',
  computed: {
    sortOptions () {
    // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => { return { text: f.label, value: f.key } })
    },
    isOwner () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Владелец'
      else return false
    },
    isBank () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.partnertype==='Банк'
      else return false
    },
    isTerminal () {
      if (this.$store.state.authentication.user)
        return this.$store.state.authentication.user.role==='terminal'
      else return false
    }
  },
  watch: {
    supplier: function () {
      this.select2=null
    },
    type: function () {
      this.selectLists()
    }
  },
  methods: {
    action (method) {
        if (this.requisite&&this.select1) {
          axios
              .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', type: ['getSignatureKey','Payment'], method: method[0], requisite: this.requisite,
                                                        amount: this.amount*100, servicetype: this.select1, userid: this.$store.state.authentication.user.id,
                                                            token: this.$store.state.authentication.user.token})
              .then(response => {
                console.log(JSON.parse(response.data));
                if (method[0]==='validate' && method[1]==='makePayment' && JSON.parse(response.data).statusCode==='1') {
                  axios
                      .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', type: ['getSignatureKey','Payment'], method: method[1], requisite: this.requisite,
                                                                amount: this.amount*100, servicetype: this.select1, userid: this.$store.state.authentication.user.id,
                                                                    token: this.$store.state.authentication.user.token})
                      .then(response => {
                        console.log(JSON.parse(response.data).PaymentStatus[0]);
                        let mes = '';
                        if (JSON.parse(response.data).PaymentStatus[0]==='Accepted') mes='Платеж проведен';
                        else mes='Ошибка';
                          this.$fire({
                                        title: "",
                                        text: mes,
                                        timer: 5000
                                    })
                                  })

                      .catch(error => {
                        console.log(error);
                        if (error.response.status === 401) {
                          this.$store.dispatch('authentication/logout');
                          this.$router.push('/')
                        } else alert(error)
                      })
                } else {
                  this.$fire({
                                title: "",
                                text: JSON.parse(response.data).message,
                                timer: 5000
                            })
                }
              })
              .catch(error => {
                console.log(error);
                if (error.response.status === 401) {
                  this.$store.dispatch('authentication/logout');
                  this.$router.push('/')
                } else alert(error)
              })
            }
        else {this.$fire({
                            title: "",
                            text: 'Введите реквизит и выберите сервис',
                            type: "error",
                            timer: 5000
                          })
        }
    },
    filterList (items,property,value) {
      if (value&&value!='')
        return items.filter(v => v[property] == value);
      else return items;
    },
    selectLists () {
      if (this.type) {
        axios
            .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', partnerId: this.$store.state.authentication.user.partnerid,
                                                          partnertype: this.$store.state.authentication.user.partnertype,
                                                          bankId: '',
                                                          type: this.type, status: false,
                                                          token: this.$store.state.authentication.user.token})
            .then(response => {
                    let i = 0;
                    this.selectList1 = response.data.filter(v => v.reqtype === this.selectList.filter(v => v.label === 'select1')[0].type);
                    for ( i = 0; i < this.selectList1.length; i++) {
                      this.selectList1[i].value=this.selectList1[i][this.selectList.filter(v => v.label === 'select1')[0].value]
                      this.selectList1[i].text=this.selectList1[i][this.selectList.filter(v => v.label === 'select1')[0].text];
                    }
                  })
            .catch(error => {
              if (error.response.status === 401) {
                  // auto logout if 401 response returned from api
                  this.$store.dispatch('authentication/logout');
                  this.$router.push('/')
              } else alert(error)
            })
      }
    },
    tablefields () {
      axios
          .post(`${config.apiUrl}/query/getdata`, { DB: 'prodDB', type: ['PageConfig'], page: 'payment', partnerType: this.$store.state.authentication.user.partnertype,
                                                        partnerId: this.$store.state.authentication.user.partnerid, partnerUserRole: this.$store.state.authentication.user.role,
                                                        token: this.$store.state.authentication.user.token})
          .then(response => {
                  this.selectList=JSON.parse(response.data.filter(v => v.type === 'Select')[0].value)
                  this.type=[];
                  this.firstList=[];
                  this.selectList.forEach((item) => {if (item.type) this.type.push(item.type); if (item.first) this.firstList.push(item.first)})
                })
          .catch(error => {
            if (error.response.status === 401) {
              this.$store.dispatch('authentication/logout');
              this.$router.push('/')
            } else alert(error)
          })
    }
  },
  mounted () {
    this.role=this.$store.state.authentication.user.role;
    this.tablefields();
  }
}
</script>
<style scoped>
.tablebody{
  background-color: #21252b;
  font-size: 14px;
}
.info {
  display: flex;
  padding: 10px;
  justify-content: center;

}
.paycount {
  display: block;
  width: auto;
  margin: 10px;
  padding: 5px 10px;
  text-align: center;
  background-color: #6c757d;
  color: orange;
  border-radius: 3px;
}
</style>
